import {createTheme} from '@mui/material/styles';

const fontFamily = [
    'Montserrat',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
].join(',');

const colorPalette = {
    primary: {
        main: '#001463',
    },
    text: {
        black: '#000545',
        grey: '#7E85A3',
        blue: '#6CAEE0',
        blueGrey: '#9CA5C9'
    },
    background: {
        lightGrey: '#F5F7FF',
        lines: '#E3E7F7',
        iconGrey: '#C5CADE',
        tagsGrey: '#E9EDFE'
    },
    orange: {
        main: '#F7941E',
        dark: '#d77a06',
        light: '#f8a544',
        contrastText: '#fff'
    },
    orangeChip: {
        main: '#FFEED9',
        dark: '#FFEED9',
        light: '#FFEED9',
        contrastText: '#F7941E'
    },
    red: {
        main: '#F8485E',
        dark: '#ce1c36',
        light: '#FFF0EA',
        contrastText: '#fff'
    },
    blue: {
        main: '#1E3076',
        light: '#273c90',
        dark: '#000545'
    },
    green: {
        main: '#E5FCF0',
        dark: '#66C293',
        light: '#66C293',
        contrastText: '#66C293'
    },
    greenButton: {
        main: '#66C293',
        dark: '#4cc687',
        light: '#8aeab8',
        contrastText: '#fff'
    },
    defaultButton: {
        main: '#F5F7FF',
        contrastText: '#7E85A3'
    },
    sand: {
        main: '#F9F5D6',
        dark: '#D8A14E',
        contrastText: '#D8A14E'
    },
    grey: {
        main: '#F5F7FF',
        dark: '#7E85A3',
        contrastText: '#7E85A3',
        lines: '#E3E7F6'
    },
    white: {
        main: '#fff',
        dark: '#f6f6f6',
        contrastText: '#001463'
    }
}

export const theme = createTheme({
    components: {
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    fontWeight: 600,
                    borderRadius: 10,
                    backgroundColor: '#F5F7FF',
                    '&.Mui-selected': {
                        backgroundColor: '#FCE300 !important',
                        color: '#001463',
                        fontWeight: 600,
                    }
                },
                today:{
                    backgroundColor: '#001463 !important',
                    color: '#fff'
                }
                /*dayOutsideMonth: {
                    backgroundColor: 'none'
                }*/
            },
        },
        MuiPopover: {
            styleOverrides: {
                paper: {
                    borderRadius: 15
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    '& *': {
                        fontSize: 14,
                    }
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: '6px',
                    fontWeight: 600,
                    height: 28
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    '& *': {
                        fontSize: 20
                    }
                }
            }
        }
    },
    custom: {
        fontSizeSm: 20
    },
    palette: colorPalette,
    typography: {
        allVariants: {
            color: colorPalette.text.black
        },
        fontFamily: fontFamily,
        body1: {
            fontSize: 14,
            fontWeight: 500
        },
        h1: {
            fontSize: 34,
            fontWeight: 400
        },
        h2: {
            fontSize: 28,
            fontWeight: 600
        },
        h3: {
            fontSize: 22,
            fontWeight: 700
        },
        h4: {
            fontSize: 20,
            fontWeight: 700
        },
        h5: {
            fontSize: 17,
            fontWeight: 700
        },
        description: {
            color: '#7E85A3',
            fontSize: 14,
            fontWeight: 500,
            fontFamily: fontFamily
        },
        orange: {
            color: colorPalette.orangeChip.contrastText
        },
        green: {
            color: colorPalette.green.contrastText
        },
    },
});