import axiosInstance from "../apiConfig";

const markAsNoContact = async (id) => {
  const response = await axiosInstance.put(`/profile/${id}/no-contact`);
  return response.data;
}

const updateProfile = async (id, data) => {
  try {
    const response = await axiosInstance.put(`/profile/${id}`, data);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
  
}

const profilesApi = { markAsNoContact, updateProfile };
export default profilesApi;