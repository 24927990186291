import { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, TextField, Button } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { UploadCsv } from "../components/UploadCsv";
import { CustomSnackbar } from "../components/CustomSnackbar";
import candidatesApi from "../api/requests/candidatesApi";

export const ImportCandidates = () => {
  const [jobId, setJobId] = useState();
  const [file, setFile] = useState({ name: undefined, data: undefined });
  const [ snackbar, setSnackBar ] = useState({ open: false, type: undefined, message: undefined });
  
  const onFileUpload = (e) => {
    if(e.target.files.length !== 0 ) {
      setFile({ name: e.target.files[0].name, data: e.target.files[0] });
    }else {
      setFile({ name: undefined, data: undefined });
    }
  };

  const onImportingCandidates = async (e) => {
    e.preventDefault();
    if(!file.data) {
      return;
    }
    candidatesApi.importCandidates(jobId, file)
      .then(res => setSnackBar({
        open: true,
        message: `Imported ${res.length} new candidates`,
        type: 'success'
      }))
      .catch(err => {
        setSnackBar({
          open: true,
          message: err.toString(),
          type: 'warning'
        });
      });   
  };

  const onChangeJob = (e) => {
    setJobId(e.target.value);
  };

  const onCloseSnackbar = () => {
    setSnackBar({ ...snackbar, open: false });
  }

  return (
    
    <Box component="form" onSubmit={onImportingCandidates} display="flex" justifyContent="center" minHeight="100vh">
      <Grid spacing={22} container direction="column">
        <Grid spacing={1} container direction="column" sx={{alignItems:"center"}}>
          <Grid item>
            <TextField label="Job Id" type="number" variant="outlined" required onChange={onChangeJob} />
          </Grid>
          <Grid item>
            <UploadCsv onFileUpload={onFileUpload} title={file.name}/>
          </Grid>
          <Grid item>
            <Button variant="contained" size="large" endIcon={<CloudUploadIcon />} type="submit">
              Upload
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <CustomSnackbar isOpen={snackbar.open} message={snackbar.message} type={snackbar.type} onClose={onCloseSnackbar}/>
    </Box>
  );
};
