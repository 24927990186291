import axios from 'axios';

//https://redux.js.org/faq/code-structure#how-can-i-use-the-redux-store-in-non-component-files
let store;
export const injectStore = _store => {
  store = _store
}

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  paramsSerializer: { indexes: null }
});

axiosInstance.interceptors.request.use(async (config) => {
  const accessToken = store.getState()?.user?.user?.accessToken;
  config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
});

export default axiosInstance;

