import { useEffect, useState } from "react";
import { Snackbar, Box, Alert } from '@mui/material';
import {
    DataGrid
  } from '@mui/x-data-grid';
import { useDispatch } from 'react-redux';
import { fetchCandidates } from '../../store/slices/candidates/candidate';

export const CandidateData = ({ dataRow, columns, rowModesModel, handleRowModesModelChange, handleRowEditStop, mySaveOnServerFunction,handleProcessRowUpdateError, pagination, filters, classes, loading, snackbar, handleCloseSnackbar, noRowsLabel='There are no data to display'}) => {
  const dispatch = useDispatch();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });
  const [rowCountState, setRowCountState] = useState(
    pagination?.totalElements || 0,
  );
  const [ isLoading, setIsLoading] = useState(loading);

  const handlePaginationModelChange = (newPaginationModel) => {
    // We have the cursor, we can allow the page transition.
    setIsLoading(true);
    if (newPaginationModel.pageSize !== paginationModel.pageSize) {
      newPaginationModel.page = 0;
    }
    setPaginationModel(newPaginationModel);
    dispatch(fetchCandidates({
      jobs: filters.job ? filters.job.value : null,
      qirStatus: filters.qirStatus ? filters.qirStatus.value : null,
      keyword: filters.searchKeyword,
      missingProfileData: filters.missingProfileData ? filters.missingProfileData : null,
      size: newPaginationModel.pageSize,
      page: newPaginationModel.page,
    })).then(({meta}) => {
      if (meta.requestStatus === 'fulfilled') {
        setIsLoading(false);
      }
      
    }) 
  };

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
    pagination?.totalElements !== undefined
        ? pagination?.totalElements
        : prevRowCountState,
    );
  }, [pagination?.totalElements, setRowCountState]);

  useEffect(() => {
    setPaginationModel({ ...paginationModel, page: 0 })
  }, [filters]);

  return (
        <Box
          sx={{
            height: '60vh',
            width: '98%',
            '& .actions': {
              color: 'text.secondary',
            },
            '& .textPrimary': {
              color: 'text.primary',
            },
          }}
        >
          {!!snackbar && (
            <Snackbar
              open
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              onClose={handleCloseSnackbar}
              autoHideDuration={6000}
            >
              <Alert {...snackbar} onClose={handleCloseSnackbar} sx={{ whiteSpace: 'pre-line' }} />
            </Snackbar>)}
          <DataGrid
            rows={dataRow}
            columns={columns}
            editMode="row"
            className={classes.grid}
            rowModesModel={rowModesModel}
            getRowId={(row) => row.id}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={(updatedRow, originalRow) =>
                mySaveOnServerFunction(updatedRow)
            }
            onProcessRowUpdateError={handleProcessRowUpdateError}
            pageSizeOptions={[20, 50, 100]}
            initialState={{
                pagination: { paginationModel: { pageSize: 20 } },
            }}
            paginationMode="server"
            onPaginationModelChange={handlePaginationModelChange}
            paginationModel={paginationModel}
            onPageSizeChange={(newPageSize) => setPaginationModel(old => ({ ...old, pageSize: newPageSize }))}
            loading={isLoading}
            localeText={{ noRowsLabel: noRowsLabel }}
            rowCount={rowCountState}
          />
        </Box>
      );
}