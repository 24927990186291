import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null
}

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
    }
  }
});

export const { actions: userActions, reducer: userReducer } = authSlice;