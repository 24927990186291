import { Box, 
  Button, 
  Switch, 
  FormControlLabel, 
  FormGroup, 
  Typography, 
  Input, 
  FormControl, 
  InputAdornment,
  Backdrop,
  CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from "@mui/icons-material/Clear";
import { useSelector, useDispatch } from 'react-redux';

import { Selector } from "../components/Selector"; 
import { fetchJobs } from "../store/slices/jobs/job";
import TextField from '@mui/material/TextField';
import { Autocomplete, createFilterOptions } from "@mui/material";
import candidatesApi from "../api/requests/candidatesApi";
import { fetchCandidates } from '../store/slices/candidates/candidate';
import { CandidatesTable } from '../components/CandidatesTable/CandidatesTable';
import { candidateQirStatuses } from "../constants/candidates/candidateQirStatus";
import { status } from "../constants/jobs/status";

const initialFilters = {
  qirStatus: null,
  job: null,
  searchKeyword: '',
  missingProfileData: false
}

const SearchIconComponent = () => {
  return (
    <InputAdornment position="end">
      <SearchIcon edge="end" />
    </InputAdornment>
  );
}

const initialState = {
  first: false,
  last: false,
  number: 0,
  numberOfElements: 0,
  size: 20,
  sort: null,
  totalElements: 0,
  totalPages: 0
};

export const Candidates = () => {
  const dispatch = useDispatch();
  const { jobsSelect } = useSelector((state) => state.jobs);
  const { candidates} = useSelector((state) => state.candidates);
  const [ filters, setFilters ] = useState({ ...initialFilters });
  const [ pagination, setPagination ] = useState(initialState);
  const [ jobSelected, setJobSelected ] = useState(null);
  const [ loading, setLoading] = useState(null);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };
  const handleOpenBackdrop = () => {
    setOpenBackdrop(true);
  };
  
  useEffect(() => {
    dispatch(fetchJobs({ status: status.OPEN }));
  },[dispatch]);

  useEffect(() => {
    handleOpenBackdrop();
    setLoading(true);
    dispatch(fetchCandidates({
      jobs: filters.job ? filters.job.value : null,
      qirStatus: filters.qirStatus ? filters.qirStatus.value : null,
      keyword: filters.searchKeyword,
      missingProfileData: filters.missingProfileData ? filters.missingProfileData : null,
      size: pagination.size,
      page: pagination.number
    })).then(({meta}) => {
      if (meta.requestStatus === 'fulfilled') {
        handleCloseBackdrop();
        setLoading(false);
      } 
      
    })
  }, [dispatch, filters]);

  const onChangeQirStatus = (qirStatus) => {
    setFilters({ ...filters, qirStatus })
  }

  const onChangeJob = (e, job) => {
    setJobSelected(job);
    setFilters({ ...filters, job })
  }

  const onResetFilters = () => {
    setJobSelected(null);
    setFilters({ ...initialFilters });
  }

  const onSearch = (searchKeyword) => {
    setFilters({ ...filters, searchKeyword })
  }

  const onFilterByNullProfiles = (e) => {
    const newMissingProfileData = e.target.checked;
    setFilters({ ...filters, missingProfileData: newMissingProfileData })
  }

  const onExport = () => {
    candidatesApi.exportCandidates({
      jobs: filters.job ? filters.job.value : null,
      qirStatus: filters.qirStatus ? filters.qirStatus.value : null,
      keyword: filters.searchKeyword,
      missingProfileData: filters.missingProfileData ? filters.missingProfileData : null
    });
  }
  
  return (
    <Box
    display="flex"
    flexDirection="column"
    minHeight="100vh"
    alignItems={"center"}
    >
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Autocomplete
          filterOptions={filterOptions}
          freeSolo
          disableClearable
          clearOnBlur={true}
          options={jobsSelect}
          value={jobSelected}
          sx={{ width: 300 }}
          onChange={onChangeJob}
          renderOption={(props, option) => (
            <li {...props} key={option.value}>
                {option.label}
            </li>
          )}
          renderInput={(params) => <TextField
            {...params}
            label="Job"
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
          />}
        />
        <Selector title={'QIR Status'} items={Object.values(candidateQirStatuses)} onSelectItem={onChangeQirStatus} defaultItem={filters.qirStatus} />
        <Box sx={{ cursor: 'pointer' }} display="flex" flexDirection="row" alignItems="center"  onClick={onResetFilters}>
          <ClearIcon sx={{color: 'text.grey'}} fontSize={'small'}/>
          <Typography variant={'description'}>Clear Filters</Typography>
        </Box>
      </Box>
      <FormControl variant="standard" sx={{ m: 1, mt: 3, width: '25ch' }}>
        <Input 
          value={filters.searchKeyword} 
          endAdornment={<SearchIconComponent/>}
          onChange={(e) => onSearch(e.target.value)}
        />
      </FormControl>
      <Button variant="contained" onClick={onExport}>Export to CSV</Button>
      <Box sx={{ cursor: 'pointer', width:'95%'}} display="flex" flexDirection="row-reverse" >
        <FormGroup>
          <FormControlLabel control={<Switch 
                                        size="larger" 
                                        onChange={onFilterByNullProfiles}
                                        inputProps={{ 'aria-label': 'secondary checkbox' }} 
                                        />} label="Filter by Null Profiles" />
        </FormGroup>
      </Box>
      {candidates && <CandidatesTable candidates={candidates} pagination={candidates.pagination} filters={filters} loading={loading} />} 
    </Box>
  );
};