import axiosInstance from "../apiConfig";

const importCandidates = async (jobId, file) => {
  const formdata = new FormData();
  formdata.append("jobId", jobId);
  formdata.append("candidates", file.data, file.name);
  const response = await axiosInstance.post('/candidate/upload', formdata, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
}

const fetchCandidates = async (filters) => {
  const response = await axiosInstance.get('/candidate/open', {
    params: { 
      ...filters,
    }
  });
  return response.data;
}

const updateQirStatus = async (id, qirStatus) => {
  return await axiosInstance.put(`/candidate/${id}/qir-status?qirStatus=${qirStatus}`);
}

const exportCandidates = async (filters) => {
  const response = await axiosInstance.get('/candidate/ingestor-export', {
    params: { 
      ...filters,
    },
    responseType: 'blob'
  });
  const blob = await response.data;
  generateCSV(blob);
}

const generateCSV = (blob, fileName = 'candidates') => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${fileName}.csv`;
  document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
  a.click();
  a.remove();  //afterwards we remove the element again
}

const candidatesApi = { importCandidates, fetchCandidates, updateQirStatus, exportCandidates };
export default candidatesApi;

