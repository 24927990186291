import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { useEffect, useState } from 'react';

export const Selector = ({ title, items, defaultItem, onSelectItem }) => {
  const handleChange = (item) => onSelectItem(item.target.value);
  const [ selectedItem, setSelectedItem ] = useState('');

  // https://github.com/mui/material-ui/issues/18494
  // MUI Select component: the value should be the exact instance of the object from the list of options
  useEffect(() => {
    if(defaultItem) {
      const index = items.findIndex(item => item.value === defaultItem.value);
      setSelectedItem(items[index]);
    }else {
      setSelectedItem('');
    }
  },[defaultItem, items]);

  return (
    <FormControl sx={{ m: 1, width: '20ch' }}>
      <InputLabel>{title}</InputLabel>
      <Select value={selectedItem} onChange={handleChange} label={title}>
        {items.map(item => <MenuItem key={item.value} value={item}>{item.label}</MenuItem>)}
      </Select>
    </FormControl>
  )
}