import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import profilesApi from '../../../api/requests/profilesApi';
import candidatesApi from '../../../api/requests/candidatesApi';

export const fetchCandidates = createAsyncThunk(
  'candidates/getCandidates',
  async (filter) => {
    return await candidatesApi.fetchCandidates(filter);
  }
);

export const rateCandidate = createAsyncThunk(
  'candidates/rateCandidate',
  async ({ id, rate }) => {
    return await candidatesApi.rateCandidate(id, rate);
  }
);
 
export const updateCoverNote = createAsyncThunk(
  'candidates/note',
  async ({ id, note }, { rejectWithValue }) => {
    try {
      const resp = await candidatesApi.updateCoverNote(id, note);
      return resp.data;
    }catch(err) {
      return rejectWithValue(err.response.data)
    }
  }
);

export const updateQirStatus = createAsyncThunk(
  'candidates/updateQirStatus',
  async ({ id, status }, { rejectWithValue }) => {
    try {
      const resp = await candidatesApi.updateQirStatus(id, status);
      return resp.data;
    }catch(err) {
      return rejectWithValue(err.response.data)
    }
  }
);

export const markAsNoContact = createAsyncThunk(
  'candidates/markAsNoContact',
  async ({ profileId, candidateId }, { rejectWithValue }) => {
    try {
      const resp = await profilesApi.markAsNoContact(profileId);
      return resp.data;
    }catch(err) {
      return rejectWithValue(err.response.data)
    }
  }
);

export const updateProfile = createAsyncThunk(
  'candidates/updateProfile',
  async ({ profileId, data }) => {
    return await profilesApi.updateProfile(profileId, data);
  }
);

const initialState = {
  candidates: [],
  isLoading: false,
  filters: {},
  pagination: {
    pageable: {},
    totalElements: 0,
    totalPages: 0,
    last: false,
    size: 20,
    number: 0,
    sort: {
        empty: true,
        sorted: false,
        unsorted: true
    },
    numberOfElements: 0,
    first: true,
    empty: false
  },
}

export const candidatesSlice = createSlice({
  name: 'candidates',
  initialState: initialState,
  reducers: {
  },
  extraReducers: {
    [fetchCandidates.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchCandidates.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.filters = action.meta.arg;
      state.candidates = action.payload.content;
      state.candidates.pagination = {
        pageable: action.payload.pageable,
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages,
        last: action.payload.last,
        size: action.payload.size,
        number: action.payload.number,
        sort: {
            empty: true,
            sorted: false,
            unsorted: true
        },
        numberOfElements: action.payload.numberOfElements,
        first: action.payload.first,
        empty: action.payload.empty
      };
    },
    [fetchCandidates.rejected]: (state) => {
      state.isLoading = true;
      state.candidates = [];
      state.pagination = [];
    },

    [rateCandidate.pending]: (state) => {
      state.isLoading = true;
    },
    [rateCandidate.fulfilled]: (state, action) => {
      const ratedCandidate = action.payload;
      const index = state.candidates.findIndex(c => c.id === ratedCandidate.id);

      state.isLoading = false;
      state.candidates[index].internalStatus = ratedCandidate.internalStatus;
      // Hanlde filters
      state.candidates = state.candidates.filter(can => 
        ((state.filters.qirStatus !== null && state.filters.qirStatus !== '') ? can.qirStatus.value === state.filters.qirStatus : can.qirStatus.value !== '') && can.internalStatus === state.filters.internalStatus);
    },
    [rateCandidate.rejected]: (state) => {
      state.isLoading = true;
    },

    [updateQirStatus.pending]: (state) => {
      state.isLoading = true;
    },
    [updateQirStatus.fulfilled]: (state, action) => {
      const updatedCandidate = action.payload;
      const index = state.candidates.findIndex(c => c.id === updatedCandidate.id);
      
      state.isLoading = false;
      state.candidates[index].qirStatus = updatedCandidate.qirStatus;
      // Hanlde filters
      state.candidates = state.candidates.filter(can => 
        ((state.filters.qirStatus !== null && state.filters.qirStatus !== '') ? can.qirStatus.value === state.filters.qirStatus : can.qirStatus.value !== '') && can.internalStatus === state.filters.internalStatus);
    },
    [updateQirStatus.rejected]: (state, action) => {
      state.isLoading = true;
    },
    [markAsNoContact.pending]: (state) => {
      state.isLoading = true;
    },
    [markAsNoContact.fulfilled]: (state, action) => {
      const { profileId } = action.meta.arg;
      state.candidates = state.candidates.filter(can => can.profile.id !== profileId);
      state.isLoading = false;
    },
    [markAsNoContact.rejected]: (state, action) => {
      state.isLoading = true;
    },
    [updateCoverNote.pending]: (state) => {
      state.isLoading = true;
    },
    [updateCoverNote.fulfilled]: (state, action) => {
      const updatedCandidate = action.payload;
      const index = state.candidates.findIndex(c => c.id === updatedCandidate.id);
      state.isLoading = false;
      state.candidates[index].coverNote = updatedCandidate.coverNote;
    },
    [updateProfile.pending]: (state) => {
      state.isLoading = true;
    },
    [updateProfile.fulfilled]: (state, action) => {
      const { profileId, data } = action.meta.arg;
      const index = state.candidates.findIndex(c => c.profileId === profileId);
      const candidate = state.candidates.find(c => c.profileId === profileId);
      data.id = candidate.id;
      state.candidates[index].profile = {...data};
      state.isLoading = false;
    },
    [updateProfile.rejected]: (state, action) => {
      state.isLoading = true;
    },

  }
});

export default candidatesSlice.reducer;