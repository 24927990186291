import axiosInstance from "../apiConfig";

const fetchJobs = async (filters) => {
  const response = await axiosInstance.get('/job', {
    params: { 
      ...filters,
    }
  });
  return response.data;
}

const jobsApi = { fetchJobs };
export default jobsApi;