import { withAuthenticationRequired } from '@auth0/auth0-react';

import { NavBar } from './NavBar';
import { useTitle } from '../hooks/useTitle';
import { useAuthUserInfo } from '../hooks/useAuthUserInfo';

export const ProtectedRoute = ({ component, title, ...args }) => {
  useAuthUserInfo();
  useTitle(title);

  const Component = withAuthenticationRequired(component, args);
  return (
    <>  
      <NavBar />
      <Component />
    </>
  )
};


