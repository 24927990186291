import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import jobsApi from "../../../api/requests/jobsApi";

export const fetchJobs = createAsyncThunk(
  'jobs/fetchJobs',
  async (filter) => {
    return await jobsApi.fetchJobs(filter);
  }
);

export const jobsSlice = createSlice({
  name: 'jobs',
  initialState: {
    jobs: [],
    jobsSelect: []
  },
  reducers: {

  },
  extraReducers: {
    [fetchJobs.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchJobs.fulfilled]: (state, action) => {
      state.isLoading = false;
      console.log('action', action)
      state.jobs = action.payload;
      state.jobsSelect = action.payload.map(job => ({ value: job.id, label: job.id + ' - ' + job.title }));
    },
    [fetchJobs.rejected]: (state) => {
      state.isLoading = true;
    }
  }
});

export default jobsSlice.reducer;