import { useAuth0 } from "@auth0/auth0-react";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, AppBar, Toolbar, Button, Divider } from '@mui/material';

import { routes } from '../constants/routes';
import { Logo } from "./Logo";

export const NavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth0();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{marginBottom: '1%'}}>
        <Toolbar>
          <Logo onClick={() => navigate(routes.importCandidates.path)}/>
          <Grid spacing={8} container sx={{ flexGrow: 1, marginX: '10%' }}>
            {Object.values(routes).map(route => {
              return (
                <Grid item key={route.path}>
                  <Button sx={{ color: location.pathname === route.path ? 'red.main' : 'grey.main' }} onClick={() => navigate(route.path)}>
                    {route.title}
                  </Button>
                </Grid>
              )
            })}
          </Grid>
          <Divider />
          <Button sx={{color: 'grey.main'}} onClick={() => logout({ returnTo: process.env.REACT_APP_AUTH0_REDIRECT_URI })}>
            LogOut
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}