import React from 'react';
import PropTypes from 'prop-types'
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const CustomSnackbar = ({ isOpen, type, message, onClose, duration }) => {
  return (
    <Snackbar sx={{ width: '30%' }} open={isOpen} autoHideDuration={duration} onClose={onClose}>
      <Alert severity={type} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
}

CustomSnackbar.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  message: PropTypes.string,
  type: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
}

CustomSnackbar.defaultProps = {
  duration: 2000
}