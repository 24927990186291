import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import NiceModal, { useModal } from "@ebay/nice-modal-react";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import { markAsNoContact } from "../../store/slices/candidates/candidate";

export const DsrDialog = NiceModal.create(({ profileId, candidateId, candidateName }) => {
  const modal = useModal();
  const dispatch = useDispatch();

  const onMarkAsNoContact = () => {
    dispatch(markAsNoContact({ profileId, candidateId }))
      .then(modal.remove());
  }

  const onClose = () => {
    modal.remove();
  };

  return (
    <Dialog
      open={modal.visible}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Do not Contact - {candidateName}?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          By marking {candidateName} as "Do not Contact" will hash
          candidate's data and the candidate will dissapear from any search results.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onMarkAsNoContact} autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
});
