import { ThemeProvider } from "@mui/material";
import { Auth0Provider } from "@auth0/auth0-react";
import { Routes, Route, Navigate } from "react-router-dom";

import { theme } from "./theme";
import { routes } from "./constants/routes";
import { Candidates } from "./pages/Candidates";
import { ProtectedRoute } from './components/ProtectedRoute';
import { ImportCandidates } from './pages/ImportCandidates';

function App() {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      redirectUri={process.env.REACT_APP_AUTH0_REDIRECT_URI}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path={routes.importCandidates.path} element={<ProtectedRoute component={ImportCandidates} title="Import Candidates"/>} />
          <Route path={routes.candidates.path} element={<ProtectedRoute component={Candidates} title="Candidates"/>} />
          <Route path="*" element={ <Navigate to={routes.importCandidates.path} />} />
        </Routes>
      </ThemeProvider>
    </Auth0Provider>
  );
}

export default App;

