import PropTypes from 'prop-types'
import { Button } from "@mui/material";
import React, { Fragment }  from 'react';

export const UploadCsv = ({ onFileUpload, title }) => {
  return (
    <Fragment>
      <input
        accept=".csv"
        type="file"
        onChange={onFileUpload}
        id="icon-button-file"
        style={{ display: 'none', }}
      />
      <label htmlFor="icon-button-file">
        <Button
          variant="text"
          component="span"
          size="large"
        >
          {title}
        </Button>
      </label>
    </Fragment>
  )
}

UploadCsv.propTypes = {
  onFileUpload: PropTypes.func,
  title: PropTypes.string
}

UploadCsv.defaultProps = {
  onFileUpload: PropTypes.func,
  title: 'Select CSV file to upload'
}