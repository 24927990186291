export const candidateQirStatuses = {
  LONG_LIST: {
    label: "Long List",
    value: "LONG_LIST",
  },
  LONG_LIST_FALSE: {
    label: "Long List False",
    value: "LONG_LIST_FALSE",
  },
  SHORT_LIST: {
    label: "Short List",
    value: "SHORT_LIST",
  },
  CURATION: {
    label: "Curation",
    value: "CURATION",
  },
  CURATION_FALSE: {
    label: "Curation False",
    value: "CURATION_FALSE",
  },
  OUTREACH_PENDING: {
    label: "Outreach Pending",
    value: "OUTREACH_PENDING",
  },
  OUTREACH: {
    label: "Outreach",
    value: "OUTREACH",
  },
  OUTREACH_FALSE: {
    label: "Outreach False",
    value: "OUTREACH_FALSE",
  },
  RESPONSE: {
    label: "Response",
    value: "RESPONSE",
  },
  RESPONSE_FALSE: {
    label: "Response False",
    value: "RESPONSE_FALSE",
  },
  INTEREST: {
    label: "Interest",
    value: "INTEREST",
  },
  INTEREST_FALSE: {
    label: "Interest False",
    value: "INTEREST_FALSE",
  },
  PRE_QUALIFICATION: {
    label: "Pre-Qualification",
    value: "PRE_QUALIFICATION",
  },
  PRE_QUALIFICATION_FALSE: {
    label: "Pre-Qualification False",
    value: "PRE_QUALIFICATION_FALSE",
  },
  INTERVIEW: {
    label: "Interview",
    value: "INTERVIEW",
  },
  INTERVIEW_FALSE: {
    label: "Interview False",
    value: "INTERVIEW_FALSE",
  },
  HIRE: {
    label: "Hire",
    value: "HIRE",
  },
  HIRE_FALSE: {
    label: "Hire False",
    value: "HIRE_FALSE",
  }
};
