import { useState, useEffect, useCallback } from 'react';
import { Tooltip } from '@mui/material';
import { useDispatch } from "react-redux";
import NiceModal from '@ebay/nice-modal-react';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import LockPersonOutlined from "@mui/icons-material/LockPersonOutlined";
import { makeStyles } from "@mui/styles";
import { CandidateData } from "./CandidateData";
import {
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons
} from '@mui/x-data-grid';
import { updateProfile } from '../../store/slices/candidates/candidate';
import { QirDialog } from "./QirDialog";
import { DsrDialog } from "./DsrDialog";

const useStyles = makeStyles({
    grid: {
        display: "flex",
        flexDirection: "column-reverse"
    }
});
const DSR_FORM = "https://docs.google.com/forms/d/1_V41m3rQ5UPxmuhQdCWN6vWJSMig4ldsCy5kROiWJWM/edit?ts=637f5f5c";

export const CandidatesTable = ({ candidates, pagination, filters, loading}) => {
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  useEffect(() => {
    setRows(candidates);
  },[candidates]);

  const classes = useStyles();
  const dispatch = useDispatch();

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const handleDataSourceRequest = () => {
    window.open(DSR_FORM, '_blank');
  }

  const handleDsrCandidateModal = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    const editedRow = rows.find((row) => row.id === id);
    const candidateName = `${editedRow.profile.firstName} ${editedRow.profile.lastName}`;
    NiceModal.show(DsrDialog, { 
      profileId: editedRow.profile.id,
      candidateId: editedRow.id, 
      candidateName 
    });
  }
   
  const handleQirCandiate = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    const candidate = rows.find((row) => row.id === id);
    NiceModal.show(QirDialog, { candidate });
  }

  const mySaveOnServerFunction = (newRow) => {
    
    const data = {
        firstName: newRow.firstName,
        lastName: newRow.lastName,
        country: newRow.country,
        email: newRow.email,
        linkedInUri: newRow.linkedInUri,
        currentJobTitle: newRow.currentJobTitle,
        currentEmployer: newRow.currentEmployer
    }

    dispatch(updateProfile({profileId: newRow.profileId, data}))
        .then(({payload}) => {
            console.log(payload)
            if(payload.status === 400) {
                let message = ['Validation error:\n'];
                if (payload.message === 'validation error') {
                  for(var key in payload.fieldErrors)
                    message.push(key + ': ' + payload.fieldErrors[key] + '\n');
                }
                setSnackbar({ children: message.join(' '), severity: 'error' });
            } else {
                setSnackbar({ children: 'Candidate ' + data.firstName + ' ' + data.lastName + ' successfully saved', severity: 'success' });
            }

        })
        .catch(err => console.log(err));
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === updatedRow.id ? updatedRow : row)));
    return updatedRow;
      
  };

  const handleRowModesModelChange = useCallback((newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  }, []);

  const handleProcessRowUpdateError = useCallback((error) => {
    setSnackbar({ children: error.message, severity: 'error' });
  }, []);


  const columns = [
    {   field: 'jobId', 
        headerName: 'Job Id',
        width: 60,
        editable: false
    },
    {   field: 'firstName', 
        headerName: 'First Name', 
        width: 100,
        editable: true,
        valueGetter: (params) => {
            return params.row.profile.firstName;
        }
    },
    {   field: 'lastName', 
        headerName: 'Last Name',
        width: 100,
        editable: true,
        valueGetter: (params) => {
            return params.row.profile.lastName;
        }
    },
    {   field: 'email', 
        headerName: 'Email',
        width: 120,
        editable: true,
        valueGetter: (params) => {
            return params.row.profile.email;
        }
    },
    {   field: 'country', 
        headerName: 'Country',
        width: 100,
        editable: true,
        valueGetter: (params) => {
            return params.row.profile.country;
        }
    },
    {   field: 'linkedInUri', 
        headerName: 'Linkedin URL', 
        width: 190,
        editable: true,
        valueGetter: (params) => {
            return params.row.profile.linkedInUri;
        }
    },
    {   field: 'currentJobTitle', 
        headerName: 'Current Job Title', 
        width: 150,
        editable: true,
        valueGetter: (params) => {
            return params.row.profile.currentJobTitle;
        }
    },
    {   field: 'currentEmployer', 
        headerName: 'Current Employer', 
        width: 150,
        editable: true,
        valueGetter: (params) => {
            return params.row.profile.currentEmployer;
        }
    },
    {   field: 'qirStatus', 
        headerName: 'QIR Status', 
        width: 110,
        editable: false,
        valueGetter: (params) => {
            return params.row.qirStatus.label;
        }
    },
    {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 150,
        cellClassName: 'actions',
        getActions: ({id}) => {
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Save">
                  <SaveIcon />
                </Tooltip>
              }
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Cancel">
                  <CancelIcon />
                </Tooltip>
                
              }
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={
              <Tooltip title="Edit candidate">
                <EditIcon />
              </Tooltip>
              }
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
          icon={
            <Tooltip title="Edit Qir"> 
              <Diversity3Icon />
            </Tooltip>
          }
          label="Edit Qir"
          className="textPrimary"
          onClick={ handleQirCandiate(id)}
          color="inherit"
            />,
          <GridActionsCellItem
            icon={
              <Tooltip title="Data Source Request Form"> 
                <LockPersonOutlined />
              </Tooltip>
            }
            label="Data Source Request Form"
            className="textPrimary"
            onClick={handleDataSourceRequest}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title="Do Not Contact"> 
                <PhoneDisabledIcon />
              </Tooltip>
            }
            label="Do Not Contact"
            className="textPrimary"
            onClick={handleDsrCandidateModal(id)}
            color="inherit"
          />, 
          
        ];
      },
    },
  ];

  return (
    <>
      <CandidateData
        dataRow={rows}
        columns={columns}
        rowModesModel={rowModesModel}
        handleRowModesModelChange={handleRowModesModelChange}
        handleRowEditStop={handleRowEditStop} 
        mySaveOnServerFunction={mySaveOnServerFunction} 
        handleProcessRowUpdateError={handleProcessRowUpdateError}
        classes={classes}
        loading={loading} 
        snackbar={snackbar}
        handleCloseSnackbar={handleCloseSnackbar}
        pagination={pagination}
        filters={filters}
      />
    </>
  )
}