import { configureStore } from "@reduxjs/toolkit";

import { userReducer } from './slices/user/user';
import candidatesReducer from './slices/candidates/candidate';
import jobsReducer from './slices/jobs/job';

export default configureStore({
  reducer: {
    user: userReducer,
    candidates: candidatesReducer,
    jobs: jobsReducer
  }
});