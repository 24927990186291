import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from "react-redux";

import { userActions } from "../store/slices/user/user";

export const useAuthUserInfo = () => {

  const user = useSelector(state => state.user.user);
  const dispatch = useDispatch();

  const { getAccessTokenSilently, user: auth0User, isAuthenticated } = useAuth0();

  useEffect(() => {
    const setTokenApiConfig = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        });
        dispatch(userActions.setUser({ accessToken }));
      } catch (e) {
        console.log(e.message);
      }
    };
    if (auth0User?.sub && isAuthenticated && !user) {
      setTokenApiConfig();
    }
  }, [getAccessTokenSilently, auth0User?.sub, user, isAuthenticated, dispatch]);
};
