import { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Dialog, DialogTitle } from '@mui/material';
import { DialogActions, DialogContent } from '@mui/material';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import DialogContentText from '@mui/material/DialogContentText';

import { Selector } from "../../components/Selector";
import { updateQirStatus } from '../../store/slices/candidates/candidate';
import { candidateQirStatuses } from "../../constants/candidates/candidateQirStatus";

export const QirDialog = NiceModal.create(({ candidate }) => {
  const modal = useModal();
  const dispatch = useDispatch();

  const [ qir, setQir] = useState(candidate.qirStatus);

  const onUpdateQirStatus = () => {
    if (qir.value === candidateQirStatuses.LONG_LIST.value) {
      modal.remove();
    }

    dispatch(updateQirStatus({ id: candidate.id, status: qir.value }))
      .then(({payload}) => {
        if(payload.status === 400) {
          alert(payload.message);
        }else {
          modal.remove();
        }
      })
      .catch(err => alert(err));
  };

  return (
    <Dialog open={modal.visible} onClose={() => modal.remove()}>
      <DialogTitle sx={{ ml: 1 }}>{candidate.profile.firstName} {candidate.profile.lastName}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ ml: 1 }}>
          Jod Id: {candidate.jobId}
        </DialogContentText>
         <div>
            <Selector title={'QIR Status'} items={Object.values(candidateQirStatuses)} onSelectItem={setQir} defaultItem={qir} />
         </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => modal.remove()}>Cancel</Button>
        <Button onClick={onUpdateQirStatus}>Save</Button>
      </DialogActions>
    </Dialog>
  );
});